import { sanitizeRedirectURl } from "~/utils/sanitize-redirect-url";

const receiveMessage = (
  successRedirect: string,
  action: "login" | "signup",
) => {
  return (event: any) => {
    if (event.origin === "") {
      return;
    }
    const {
      data: { success, type },
    } = event;

    if (type !== "MINDSTONE_LOGIN") {
      return;
    }
    const redirectUrl = sanitizeRedirectURl(successRedirect);

    if (success) {
      window.location.href = redirectUrl;
    } else {
      window.location.href = `/${action}/error?previousUrl=${encodeURIComponent(
        redirectUrl,
      )}`;
    }
  };
  // Do we trust the sender of this message? (might be
  // different from what we originally opened, for example).
};

//for brave as the created window doesn't have permission to close itself
const closeWindow = (window: any) => {
  return (event: any) => {
    if (event.origin === "") {
      return;
    }
    const {
      data: { success, type },
    } = event;

    if (type !== "MINDSTONE_LOGIN") {
      return;
    }

    if (success) {
      try {
        window.close();
      } catch (error) {
        // do nothing, maybe already closed
      }
    }
  };
};

let windowObjectReference: Window | null = null;
let previousUrl: string | null = null;
export const openAuthWindow = (
  url: string,
  name: string,
  successRedirectUrl: string,
  action: "login" | "signup",
) => {
  console.log("Opening", url, "with success URL", successRedirectUrl);

  // remove any existing event listeners
  const listener = receiveMessage(successRedirectUrl, action);
  window.removeEventListener("message", listener);

  // window features
  const strWindowFeatures =
    "toolbar=no, menubar=no, width=600, height=700, top=100, left=100";

  if (windowObjectReference === null || windowObjectReference.closed) {
    /* if the pointer to the window object in memory does not exist
     or if such pointer exists but the window was closed */
    windowObjectReference = window.open(url, name, strWindowFeatures);
  } else if (previousUrl !== url) {
    /* if the resource to load is different,
     then we load it in the already opened secondary window and then
     we bring such window back on top/in front of its parent window. */
    windowObjectReference = window.open(url, name, strWindowFeatures);
    windowObjectReference?.focus();
  } else {
    /* else the window reference must exist and the window
     is not closed; therefore, we can bring it back on top of any other
     window with the focus() method. There would be no need to re-create
     the window or to reload the referenced resource. */
    windowObjectReference.focus();
  }

  // add the listener for receiving a message from the popup
  window.addEventListener("message", listener, false);

  const windowCloseListener = closeWindow(windowObjectReference);

  window.addEventListener("message", windowCloseListener, false);

  // assign the previous URL
  previousUrl = url;
};
