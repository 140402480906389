import { Cookies } from "react-cookie";

export const ExtensionVersionCookie = "x-nspr-extension-version";

export function isChromium() {
  // @ts-ignore
  return !!window.chrome;
}

function isFF() {
  return navigator.userAgent.indexOf("Firefox") !== -1;
}

function isEdge() {
  return navigator.userAgent.indexOf("Edg") !== -1; // This is not a typo, it really is Edg
}

export function isSafari() {
  return navigator.userAgent.indexOf("Safari") !== -1 && !isChromium();
}

export const isChrome = () => {
  return isChromium() && !isEdge() && !isFF();
};

export function getBrowser() {
  switch (true) {
    case isFF():
      return "firefox";
      break;
    case isEdge():
      return "edge";
      break;
    case isChrome():
      return "chrome";
      break;
    case isSafari():
      return "safari";
      break;
    default:
      return navigator.userAgent;
      break;
  }
}

export function checkExtensionInstalled() {
  const cookies = new Cookies();
  const v = cookies.get(ExtensionVersionCookie);
  return !!v;
}

export function getStoreLink() {
  if (typeof window === "undefined") {
    return "https://chrome.google.com/webstore/detail/mindstone-the-platform-fo/jadnmojgpippfggmljlocloipejjalob";
  }
  if (isSafari()) {
    return "https://apps.apple.com/app/id1558529416";
  }

  if (isEdge()) {
    return "https://microsoftedge.microsoft.com/addons/detail/cofjindfglpmfmnjoclgppibfbdmhbhm";
  }

  if (isChromium()) {
    return "https://chrome.google.com/webstore/detail/mindstone-the-platform-fo/jadnmojgpippfggmljlocloipejjalob";
  }

  if (isFF()) {
    return "https://addons.mozilla.org/en-GB/firefox/addon/mindstone/";
  }

  // Default to chrome
  return "https://chrome.google.com/webstore/detail/mindstone-the-platform-fo/jadnmojgpippfggmljlocloipejjalob";
}
