const regex = /^https:\/\/([a-zA-Z0-9._-])*mindstone\.(com|dev)(.*)$/;
export function sanitizeRedirectURl(redirectUrl: string) {
  const url = decodeURIComponent(redirectUrl);
  if (regex.test(url)) {
    console.log("URL Valid", url);
    return url;
  }
  console.log("URL Invalid", url);
  return "/";
}
