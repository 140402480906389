import React from "react";

export function GoogleIcon(props: React.ComponentProps<"svg">) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12 2.5C14.3149 2.5 16.4307 3.33443 18.0794 4.7135L15.9484 6.84446C14.855 6.00411 13.4878 5.5 12 5.5C8.41036 5.5 5.5 8.41036 5.5 12C5.5 15.5896 8.41036 18.5 12 18.5C14.8309 18.5 17.2302 16.6878 18.1228 14.1669L18.359 13.5H17.6515H12.5V10.5H20.5V10.5415H21H21.3863C21.4603 11.0177 21.5 11.5045 21.5 12C21.5 17.2464 17.2464 21.5 12 21.5C6.75364 21.5 2.5 17.2464 2.5 12C2.5 6.75364 6.75364 2.5 12 2.5Z"
        fill="#FFC107"
        stroke="#F9F9FB"
      />
      <path
        d="M3.15234 7.3455L6.43784 9.755C7.32684 7.554 9.47984 6 11.9993 6C13.5288 6 14.9203 6.577 15.9798 7.5195L18.8083 4.691C17.0223 3.0265 14.6333 2 11.9993 2C8.15834 2 4.82734 4.1685 3.15234 7.3455Z"
        fill="#FF3D00"
      />
      <path
        d="M12.0002 21.9999C14.5832 21.9999 16.9302 21.0114 18.7047 19.4039L15.6097 16.7849C14.6057 17.5454 13.3577 17.9999 12.0002 17.9999C9.39916 17.9999 7.19066 16.3414 6.35866 14.0269L3.09766 16.5394C4.75266 19.7779 8.11366 21.9999 12.0002 21.9999Z"
        fill="#4CAF50"
      />
      <path
        d="M21.8055 10.0415H21V10H12V14H17.6515C17.2555 15.1185 16.536 16.083 15.608 16.7855L15.6095 16.7845L18.7045 19.4035C18.4855 19.6025 22 17 22 12C22 11.3295 21.931 10.675 21.8055 10.0415Z"
        fill="#1976D2"
      />
    </svg>
  );
}

export default GoogleIcon;
