import React from "react";

export function AppleIcon(props: React.ComponentProps<"svg">) {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M12.7857 21.13C11.7082 21.1353 11.0437 21.4004 10.4857 21.6467C10.4534 21.6609 10.4218 21.675 10.3907 21.6888C9.89929 21.9067 9.54035 22.0658 8.9767 22.06L8.97613 22.06C8.33465 22.0541 7.73111 21.7375 7.1487 21.2043C6.56589 20.6706 6.04984 19.9615 5.58869 19.2609C4.32931 17.3428 3.63685 15.2643 3.45154 13.3773C3.26537 11.4814 3.59412 9.82366 4.31872 8.7102L4.31894 8.70985C5.38295 7.07184 7.03858 6.15123 8.53464 6.15123C9.30364 6.15123 9.93483 6.36013 10.5963 6.59569C10.6337 6.60902 10.6714 6.6225 10.7093 6.63607C11.3234 6.85583 12.0004 7.09811 12.7684 7.09811C13.4937 7.09811 14.0955 6.86915 14.6503 6.65807C14.709 6.63572 14.7672 6.61358 14.825 6.59192C15.4377 6.36225 16.0806 6.14936 16.989 6.14936C18.1683 6.14936 19.4487 6.72381 20.4643 7.77466C17.0824 10.125 17.6835 15.1952 21.2083 16.9355C20.8276 17.7647 20.5742 18.243 20.0231 19.0828C19.56 19.7873 19.0241 20.5437 18.4194 21.1232C17.8102 21.7071 17.193 22.0519 16.5674 22.0562L16.5663 22.0563C16.0127 22.0613 15.6663 21.9033 15.1897 21.686C15.154 21.6697 15.1176 21.6531 15.0804 21.6363C14.5272 21.3857 13.8679 21.1208 12.7857 21.13ZM12.7857 21.13C12.786 21.13 12.7863 21.13 12.7866 21.13L12.789 21.63L12.7847 21.13C12.785 21.13 12.7854 21.13 12.7857 21.13ZM15.0795 3.75779L15.0791 3.75837C14.4719 4.54047 13.4889 5.14892 12.5198 5.27102C12.5019 4.31937 12.9667 3.3368 13.5611 2.61298C14.1787 1.8652 15.1754 1.26426 16.0917 1.05372C16.0886 2.04668 15.6554 3.01827 15.0795 3.75779Z" />
    </svg>
  );
}

export default AppleIcon;
